@mixin diagram-highlight {
  &.DiagramBehavior__disabled,
  &.ClickBehavior__disabled {
    opacity: 1;
  }

  .djs-visual {
    rect,
    circle,
    polygon,
    path {
      stroke: var(--blue-base-65) !important;
    }

    rect,
    polygon {
      stroke-width: 3px !important;
    }

    circle:not(:only-child) {
      stroke-width: 2px !important;
    }

    polygon + path,
    text {
      fill: var(--blue-base-65) !important;
    }
  }

  &.djs-connection {
    .djs-visual {
      path {
        marker-end: url('#sequenceflow-end-highlight') !important;
        stroke-width: 3px !important;
        fill: none !important;
      }
    }
  }
}

#sequenceflow-end-highlight path {
  stroke: var(--blue-base-65) !important;
  fill: var(--blue-base-65) !important;
}
