.DashboardTile {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 0.5rem;
  max-width: 100%;

  .BPMNDiagram:not(:hover) {
    .ZoomControls {
      display: none;
    }
  }
}
