.dark {
  .rdrNextPrevButton {
    background: var(--grey-darken-36);
  }

  .rdrNextPrevButton:hover {
    background: var(--grey-darken-23);
  }

  .rdrPprevButton i {
    border-color: transparent var(--silver-darken-87) transparent transparent;
  }

  .rdrNextButton i {
    border-color: transparent transparent transparent var(--silver-darken-87);
  }

  .rdrMonthAndYearPickers select {
    color: var(--silver-darken-87);
    background: initial;
    appearance: menulist;

    option {
      background-color: var(--grey-darken-23);
    }
  }

  .rdrMonthAndYearPickers select:hover {
    background-color: rgba(0, 0, 0, 0.07);
  }

  .rdrMonthName,
  .rdrWeekDay {
    color: var(--grey-lighten-56);
  }

  .rdrDayPassive .rdrDayNumber span {
    color: var(--grey-lighten-56);
  }

  .rdrDayNumber span {
    color: var(--silver-darken-87);
  }
}

.rdrCalendarWrapper .rdrDateDisplayWrapper {
  display: none;
}
