@import 'shared-styles/shared-styles.scss';

.TaskAnalysis {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;

  h2,
  .TaskAnalysis__diagram {
    margin-bottom: 1rem;
  }

  .noOutliers {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 50%);
    border-radius: 10px;
    background-color: var(--silver-darken-94);
    font-size: 1.4rem;
    padding: 1rem;
    color: var(--grey-lighten-50);
  }

  .TaskAnalysis__diagram {
    display: flex;
    flex-grow: 1;

    &.empty .BPMNDiagram {
      opacity: 0.2;
      pointer-events: none;
    }
  }

  .OutlierDetailsTable {
    max-height: 30%;
  }
}

.DownloadButtonConfirmationModal {
  z-index: calc(var(--z-modal) + 1);
}
