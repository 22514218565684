@use '@carbon/styles/scss/spacing';

.Collection {
  height: 100%;
  padding-block: spacing.$spacing-06;

  .layoutContainer {
    .cds--tab-content {
      padding: 0;
    }
  }

  > input.hidden {
    display: none;
  }
}
