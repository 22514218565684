@use '@carbon/colors';
@import 'shared-styles/shared-styles.scss';

.PlatformLogin {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: var(--z-login);
  padding-top: 200px;
  background: var(--cds-background, #ffffff);

  button[type='submit'] {
    width: 100%;
    max-width: 100%;
  }

  .header {
    margin-bottom: 50px;
    text-align: center;

    h1 {
      color: colors.$gray-100;
      font-weight: normal;
      font-size: 1.75em;
    }
  }
}
