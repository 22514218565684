.DateRangeInput {
  > .cds--label {
    display: none;
  }

  .selectGroup {
    align-items: start;
    position: relative;
    width: fit-content;

    > .Select:first-of-type {
      width: 150px;
    }
  }

  .unitSelection {
    display: flex;
    align-items: center;

    .number {
      margin: 0 var(--cds-stack-gap);
    }

    & > span {
      margin-top: 12px;
    }
  }
}

.MenuDropdownMenu .customDate {
  border-top: 1px solid var(--cds-border-subtle);
}
