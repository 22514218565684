.SetupNotice {
  width: 100%;
  height: 100%;
  padding-top: 38px;
  border-radius: 8px;
  color: var(--grey-lighten-56);
  text-align: center;

  h1 {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 12px;
  }

  p {
    font-size: 14px;
    margin: 0;

    b {
      color: var(--grey-base-40);
    }
  }
}
