// Safari fix
.djs-container {
  > svg {
    position: absolute;
  }

  .djs-overlay-container {
    z-index: var(--z-above);
  }

  .djs-palette {
    z-index: calc(var(--z-above) + 1);
  }
}

.BPMNDiagram {
  flex-grow: 1;
  position: relative;

  &.loading .bjs-container {
    opacity: 0.5;
  }

  &.disableNavigation {
    .ZoomControls {
      display: none;
    }
  }

  .Loading {
    z-index: var(--z-above);
  }

  .Loading,
  .emptyText {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .emptyText {
    font-size: 14px;
    color: var(--grey-lighten-56);
  }

  .djs-element.hover .djs-outline,
  .djs-element.selected .djs-outline {
    stroke-width: 0;
  }
}

.bjs-powered-by {
  display: none !important;
}

.bjs-breadcrumbs {
  display: none;
}
