@use '@carbon/styles/scss/spacing';
@import 'shared-styles/shared-styles.scss';

.EventsSources {
  display: flex;
  flex-grow: 1;
  min-width: 100px;

  .sourcesList {
    flex-grow: 1;
    margin: 8px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    min-width: 100px;
    gap: spacing.$spacing-04;

    .SourceMenuButton {
      button {
        min-width: 100px;
        @include overflow-ellipsis(auto, block);
      }

      &.hidden {
        text-decoration: line-through;
      }
    }
  }
}
