.Sharing {
  height: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 30px 25px 0 40px;

  .ReportDetails .modalButton {
    display: none;
  }

  > .header {
    flex-shrink: 0;
    margin-bottom: 40px;

    .title-button {
      display: flex;
      align-items: center;
      flex-shrink: 0;

      > svg {
        margin-left: 0.5rem;
      }
    }
  }

  .title-container {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    min-height: 30px;
    justify-content: flex-end;
  }

  > .content {
    margin-bottom: 24px;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    flex-grow: 1;
    min-height: 0;

    .Table {
      min-width: 25%;
    }

    .iconLink {
      position: fixed;
      height: 20px;
      left: 10px;
      bottom: 10px;
      opacity: 0.5;
      z-index: var(--z-above);
    }
  }

  &.compact {
    &.report {
      padding: 10px;
    }

    > .header {
      margin-bottom: 15px;

      .EntityName {
        padding-right: 10px;

        > .name-container .name {
          font-size: 14px;
          font-weight: bold;
          line-height: 30px;
        }

        .Popover {
          margin-top: 4px;
        }
      }
    }

    > .content {
      margin-bottom: 0;
    }
  }
}
