@use '@carbon/styles/scss/spacing';

.DefinitionEditor {
  .diagram {
    height: 240px;
    display: flex;
    position: relative;
    border-bottom: 1px solid var(--cds-border-strong);
    padding: spacing.$spacing-06 0;

    .diagramExpandBtn {
      position: absolute;
      top: 0px;
      left: 0px;
    }
  }
}
