.OptimizeReportTile {
  &[role='link']:hover:not(.canOnlyClickTitle),
  &.canOnlyClickTitle .titleBar:hover {
    cursor: pointer;
  }

  &[role='link']:hover .EntityName a {
    text-decoration: underline;
  }

  .titleBar {
    display: flex;
    min-height: 45px;

    .Popover .ButtonTrigger {
      opacity: 0;

      &.active,
      &.focus-visible {
        opacity: 1;
      }
    }

    .EntityName {
      padding-right: 0;

      .Popover {
        margin-top: 0;
      }
    }

    .name-container .name {
      font-size: 14px;
      line-height: 18px;
    }
  }

  .InstanceCount {
    position: absolute;
    top: 0;
    right: 0;

    > .countString {
      display: none;
    }

    > span {
      vertical-align: top;
    }

    .Popover {
      display: inline-block;
    }
  }

  .visualization {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: auto;

    .Table {
      table-layout: fixed;
      min-width: 100%;
    }
  }
}

.Sharing,
.DashboardView {
  .OptimizeReportTile:hover {
    .Popover .ButtonTrigger {
      opacity: 1;
    }
  }
}
