@use '@carbon/colors';

.InstanceStateFilter {
  .Popover .ListBoxTrigger {
    .indicator {
      width: 10px;
      height: 10px;
      background-color: colors.$gray-40;
      border-radius: 50%;

      &.active {
        background-color: colors.$green-50;
      }
    }
  }
}
