.Dashboard {
  padding-top: 30px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  height: 100%;

  .DashboardEdit {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .DashboardEdit,
  .DashboardView {
    & > .content {
      overflow-y: auto;
      padding: 0 40px 0 40px;
    }

    & > .header {
      margin: 0 40px 40px 40px;

      .head {
        display: flex;
        min-width: 0;

        .info {
          display: flex;
          flex-direction: column;
          min-width: 0;
          flex-grow: 1;
        }
      }

      .tools {
        flex-wrap: nowrap;
        align-items: center;
        display: flex;
        flex-shrink: 0;
      }
    }
  }
}
