.TemplateModal {
  .cds--modal-container {
    height: 100%;

    .gridContainer {
      height: 100%;
    }
  }

  &.noProcess .definitionSelection {
    align-items: center;
    justify-content: center;

    .formArea,
    .diagramArea {
      display: none;
    }

    .noProcessHint {
      font-size: 14px;
      color: var(--cds-text-secondary);
    }
  }

  .definitionSelection {
    display: flex;
    flex-direction: column;
    padding: 20px 0;

    .Form .FormGroup {
      padding: 0;
    }

    .formArea {
      min-height: 100px;
      margin-bottom: 15px;
    }

    .diagramArea {
      flex: 1 0 auto;
      display: flex;
      flex-direction: column;
      flex-basis: 0;
      border: solid 1px var(--cds-border-subtle);
      overflow-y: auto;
      position: relative;

      .diagramContainer {
        display: flex;
        flex-grow: 0;
        flex-shrink: 0;
        flex-direction: column;
        padding: 15px;
        min-height: 0;

        &:not(:last-child) {
          border-bottom: 1px solid var(--cds-border-subtle);
        }

        .title {
          font-size: 14px;
          font-weight: 600;
          padding-bottom: 5px;
        }

        .BPMNDiagram {
          .ZoomControls {
            display: none;
          }

          &:hover .ZoomControls {
            display: block;
          }
        }
      }

      ol {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        align-self: center;
        color: var(--cds-text-secondary);
        font-size: 14px;

        li {
          margin: 5px 0;
        }
      }
    }
  }

  .configurationSelection {
    padding: 20px 0px 20px 20px;
    border-left: 1px solid var(--cds-border-subtle);
    overflow-y: auto;

    .templateContainer {
      .groupTitle {
        font-weight: 600;
        font-size: 14px;
        margin-bottom: 15px;
      }

      button {
        display: block;
        width: 100%;
        padding: 2px;
        margin-bottom: 20px;
        background-color: var(--cds-field);
        color: var(--text-primary);
        border-color: var(--text-primary);

        &:disabled {
          opacity: 0.3;
        }

        &.active {
          padding: 1px;
          border: 2px solid var(--cds-border-interactive);

          .name,
          .description {
            background-color: var(--cds-highlight);
          }
        }

        &.hasDescription {
          .name {
            padding-top: 8px;
            padding-bottom: 0;
            min-height: 26px;
          }

          .description {
            color: var(--cds-text-secondary);
            font-size: 12px;
            line-height: 1.5;
            padding: 0 10px;
            padding-bottom: 6px;
          }
        }

        img,
        .imgPlaceholder {
          margin: 10px;
          margin-bottom: 0;
          width: calc(100% - 20px);
          height: 80px;
          object-fit: contain;
        }

        .name {
          font-size: 14px;
          font-weight: 500;
          min-height: 40px;
          padding: 10px;
          line-height: 20px;
        }
      }
    }
  }
}
