.ColorPicker {
  width: 200px;

  .colorsContainer {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    .color {
      width: 25px;
      height: 25px;
      cursor: pointer;
      &:hover,
      &.active {
        box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.25);
        border: 2px solid var(--white);
      }
    }
  }
}
