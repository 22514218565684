@use '@carbon/layout';

.Notifications {
  position: absolute;
  top: 3.5rem;
  right: var(--cds-spacing-03);
  max-width: 100%;
  z-index: var(--z-notification);
  pointer-events: none;
}
