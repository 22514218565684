.AssigneeFilter__Dashboard {
  .AssigneeFilterPreview {
    .parameterName {
      display: none;
    }
    .PreviewItemValue {
      background: none;
      color: inherit;
      padding: 0;
      margin: 0;
      border: none;
      display: inline;
      vertical-align: baseline;
    }
  }

  .customValue {
    .MultiUserInput {
      width: 300px;
      .cds--list-box {
        background-color: var(--cds-field-02, #ffffff);
      }
    }
  }
}
