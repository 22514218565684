.ReportEdit {
  .Report__content {
    &.hidden {
      display: none;
    }
  }

  .Report__view {
    padding-left: 0;
  }

  .ReportControlPanel {
    margin-left: 0;
  }

  .viewsContainer,
  .mainView {
    display: flex;
    flex-direction: column;
    flex: 1;
    min-height: 0;
    overflow: hidden;
  }

  .mainView {
    padding-left: 40px;
    padding-right: 20px;
  }

  .CollapsibleContainer {
    .InstanceViewTable {
      height: calc(100% - 48px);
    }
  }
}
