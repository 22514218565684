.DateFilter {
  .Select {
    width: fit-content;
  }
  .FilterDefinitionSelection {
    margin-bottom: 0;
  }

  .DateFilterPreview {
    white-space: nowrap;

    .filterText {
      display: inline-block;
    }
  }
}
