@use '@carbon/styles/scss/spacing';

.Processes {
  padding-block: spacing.$spacing-06;

  .processOverview {
    font-size: 24px;
    line-height: 32px;
    font-weight: normal;
    color: var(--grey-darken-23);
    flex-grow: 1;
    min-height: 50px;

    .info {
      font-size: 14px;
      line-height: 18px;
      color: var(--grey-darken-33);
    }
  }

  .fullscreen {
    height: auto;
    margin-bottom: spacing.$spacing-06;

    .DashboardView .head .info > .EntityName {
      display: none;
    }

    .EntityName .Popover,
    .InstanceCount .Popover {
      display: none;
    }

    .tools {
      margin-top: 40px;
      display: none;
    }

    .FiltersView {
      border: none;
      padding: 0;
      margin: 20px 0 5px 0;

      > h3 {
        display: none;
      }
    }
  }
}
