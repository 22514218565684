@use '@carbon/styles/scss/spacing';

.NodeSelection {
  .cds--modal-container {
    height: 100%;
  }

  .modalContent {
    display: flex;
    flex-direction: column;

    .diagramActions {
      margin-bottom: spacing.$spacing-06;
    }

    .diagramContainer {
      flex-grow: 1;
      display: flex;
    }
  }
}
