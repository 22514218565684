.NodesTable {
  table {
    height: 100%;
  }

  .selection {
    display: flex;
    min-width: 0;
    flex-grow: 1;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: row;
    max-height: 100%;

    .Select {
      min-width: 0;
      width: 130px;

      &.operator {
        margin-right: 10px;
      }

      &.unit {
        margin-left: 10px;
      }
    }
  }
}
