.Filter {
  display: inline;

  .filterHeader {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    .dropdownLabel {
      font-size: 12px;
      font-weight: 500;
      color: var(--cds-text-primary);
    }

    .explanation {
      flex-grow: 1;
      margin: 0 0.5rem;

      .tooltipTriggerBtn {
        padding: 0;
        margin: 0;
        border: none;
        cursor: pointer;

        svg {
          fill: currentColor;
        }
      }

      .flowNodeFilterTooltip .cds--tooltip-content {
        max-width: 240px;
      }
    }
  }

  .emptyMessage {
    color: var(--grey-lighten-50);
    font-size: 12px;
    margin: 10px 0 0;
  }

  .linkingTip {
    color: var(--grey-lighten-50);
    font-size: 12px;
  }

  .Filter__dropdown {
    display: inline-block;
  }
}

.MenuDropdownMenu {
  min-width: 200px;
}
