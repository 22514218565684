.TextTile {
  .TextEditor {
    height: 100%;

    .Editor .editor {
      max-height: 100%;
      min-height: 0;
      border: none;
      padding: 0;
      padding-top: 0.5rem;
      background-color: var(--cds-field-02);
    }
  }
}

.DashboardView,
.Sharing {
  .EditTextTile {
    display: none;
  }
}
