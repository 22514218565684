button.AddDefinition {
  position: absolute;
  top: 0.25rem;
  right: 2.5rem;
  padding-right: 2rem;
}

.AddDefinitionModal {
  .cds--modal-container {
    height: 100%;
  }
}
