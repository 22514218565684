.Tooltip {
  display: inline-block;
  position: absolute;
  font-size: 14px;
  max-width: 500px;
  overflow-wrap: break-word;
  background-color: var(--black);
  color: var(--white);
  padding: 10px;
  margin: 7px 0;
  border-radius: 6px;
  z-index: var(--z-tooltip);
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.2);
  transform: translate(-50%, -100%) translateY(-16px);

  &:after {
    content: '';
    position: absolute;
    border: 5px solid transparent;
    margin-left: -5px;
    left: 50%;
    bottom: -9px;
    border-top-color: var(--black);
  }

  &.light {
    background-color: var(--white);
    color: var(--black);

    &:after {
      border-top-color: var(--white);
    }
  }
}
