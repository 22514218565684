.EventsSourceModal {
  .DefinitionSelection {
    .info {
      display: none;
    }

    .container .selectionPanel {
      margin-bottom: 0;
    }
  }

  .sourceOptions {
    .label.after {
      padding: 10px 0;
    }
    .displayHeader {
      display: flex;

      h4 {
        margin-right: 10px;
      }
    }
  }

  .addExternalInfo {
    font-size: 0.875em;
    color: var(--grey-lighten-56);
  }

  .editingWarning {
    max-width: 100%;
  }
}
