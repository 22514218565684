@use '@carbon/styles/scss/spacing';
@import 'shared-styles/shared-styles.scss';

.Footer {
  padding: spacing.$spacing-04 spacing.$spacing-05;
  font-size: 0.75rem;
  background-color: var(--cds-background);
  border-top: 1px solid var(--cds-border-subtle);
  color: var(--cds-text-secondary);
  cursor: default;
  flex-shrink: 0;

  display: flex;
  justify-content: space-between;

  > * {
    white-space: nowrap;
  }

  .timezone {
    margin-inline: spacing.$spacing-03;
    @include overflow-ellipsis;
  }

  .colophon {
    white-space: nowrap;
  }
}
