.DateFields {
  position: relative;

  .inputContainer {
    display: flex;
    align-items: flex-start;
    flex-wrap: nowrap;

    .PickerDateInput:first-child {
      margin-right: 15px;
    }

    .PickerDateInput:nth-child(2):before {
      left: -10px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      content: '-';
    }
  }

  .dateRangeContainer {
    position: absolute;
    width: max-content;
    left: 0;
    top: 50px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
    border: 1px solid rgba(0, 0, 0, 0.2);
    background-color: var(--white);
    padding: 5px 0;
    z-index: var(--z-popup);

    &.dateRangeContainerLeft {
      &::before {
        left: 50px;
      }

      &::after {
        left: 30px;
      }
    }

    &.dateRangeContainerRight {
      &::before {
        left: 230px;
      }
      &::after {
        left: 210px;
      }
    }

    &::before {
      content: ' ';
      display: block;
      width: 20px;
      height: 20px;
      position: absolute;
      transform: rotate(45deg);
      border: 1px solid rgba(0, 0, 0, 0.2);
      background-color: var(--white);
      top: -10px;
    }

    &::after {
      content: ' ';
      display: block;
      width: 50px;
      height: 22px;
      position: absolute;
      background-color: var(--white);
      top: 0;
    }
  }
}
