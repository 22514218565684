.TenantInfo {
  margin: 0 10px;
  background-color: var(--cds-field);
  color: var(--cds-text-primary, #161616);
  height: 2rem;
  max-height: 2rem;

  .tenantName {
    margin: 0;
    width: 100%;
    height: calc(100% + 1px);
    background-color: var(--cds-field-02, #ffffff);
    position: relative;
    display: inline-flex;
    overflow: hidden;
    align-items: center;
    padding: 0 1rem;
    outline: none;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &:last-child {
    margin-right: 0;
  }
}
