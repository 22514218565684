.Checklist {
  &.customHeader {
    th:first-child {
      display: none;
    }
  }

  .cds--search {
    flex: 1;
  }

  table {
    tbody {
      min-height: 200px;

      tr {
        cursor: pointer;

        td {
          border-bottom: none;
        }
      }
    }
  }

  &.headerHidden {
    thead {
      display: none;
    }
  }
}
