@import 'shared-styles/shared-styles.scss';

.bjs-container:not(.highlight-single-path) .analysis-part-highlight {
  @include diagram-highlight;
}

.DiagramBehavior__clickable {
  cursor: pointer;
}

.DiagramBehavior__disabled {
  opacity: 0.3;
}

.djs-shape.ClickBehavior__node {
  opacity: 1;
}

.DiagramBehavior__highlight .djs-outline {
  stroke-width: 2px !important;
  stroke: var(--blue-darken-62) !important;
  fill: var(--silver-darken-80) !important;
  fill-opacity: 0.4;
}

.DiagramBehavior__selected .djs-outline {
  visibility: visible;
  shape-rendering: initial !important;
  stroke-dasharray: none !important;
  stroke-width: 2px !important;
  stroke: var(--blue-darken-62) !important;
  fill: var(--blue-lighten-82) !important;
  fill-opacity: 0.6;
}

.DiagramBehavior__selected.hover .djs-outline {
  stroke-width: 2px !important;
  stroke: var(--blue-darken-55) !important;
  fill: var(--blue-darken-48) !important;
  fill-opacity: 0.6;
}

g.chart-hover path {
  stroke: var(--red-darken-38) !important;
  stroke-width: 3px !important;
}

.DiagramBehavior__overlay {
  display: inline-block;
  background-color: var(--white);
}

.DiagramBehavior__end-event-statistics {
  border: 1px solid var(--grey-lighten-50);
  width: 350px;
  margin-bottom: 0;

  tr {
    background-color: var(--white);
  }

  td {
    vertical-align: middle;
  }

  td:first-child {
    font-size: 2rem;
    text-align: right;
  }
}
