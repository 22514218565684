.SourcesList {
  height: 100%;

  .EntityList .meta {
    flex: 0 0 360px;
  }

  .conflictList {
    list-style: initial;
    padding-left: 40px;
  }
}
