@use '@carbon/styles/scss/spacing' as *;

.Loading {
  padding-top: $spacing-02;
  padding-bottom: $spacing-02;
  width: 100%;
  height: 100%;
  max-height: 100%;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
