.RenameVariablesModal {
  .cds--modal-container {
    height: 100%;
  }

  .cds--modal-content {
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .header {
      display: flex;

      .guidelines {
        list-style: initial;
        padding-left: 40px;
      }
    }
  }
}
