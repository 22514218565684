@use '@carbon/colors';

.KpiCreationModal {
  .filterTile {
    display: flex;
    align-items: center;
    gap: 10px;

    .actions {
      margin-left: auto;
    }
  }

  .preview {
    min-width: 0;
    text-align: center;

    img {
      width: 100%;
      max-width: 400px;
    }
  }

  .text-mandatory {
    color: var(--cds-text-error, #da1e28);
  }

  .preview {
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      max-width: 400px;
      height: 100%;
      width: auto;
      object-fit: contain;
    }
  }
}

.instanceEndDateKpiModal {
  .cds--inline-notification,
  .tip {
    display: none;
  }
}

.executedFlowNodesKpiModal {
  .modalContent p,
  .diagramActions {
    display: none;
  }
}
