.Report-container {
  padding-top: 30px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  min-height: 0;
  height: 100%;
}

.Report {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  min-height: 0;

  &.ReportEdit {
    .reportHeader {
      .headerTopLine {
        .RunPreviewButton {
          margin-left: 6px;
          min-width: auto;
          align-self: flex-start;
        }
      }

      .headerBottomLine {
        justify-content: space-between;
      }
    }
    .Report__view {
      margin-top: 10px;
      border-top: 1px solid var(--silver-darken-87);

      .Heatmap > .Select {
        transform: translateY(-42px);
      }
    }
  }
}

.reportHeader {
  margin-bottom: 20px;
  margin: 0 40px;

  .headerTopLine,
  .headerBottomLine {
    display: flex;
  }

  .head {
    display: flex;
    flex-grow: 1;
    min-width: 0;

    .info {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      min-width: 0;
    }
  }

  .tools {
    display: flex;
    flex-wrap: nowrap;
    align-items: baseline;
    flex-shrink: 0;

    .tool-button {
      margin-right: 6px;
    }

    .tool-button:last-child {
      margin-right: 0;
    }
  }
}

.Report__heading {
  font-size: 2em;
  font-weight: bold;
  margin: 0;
}

.Report__view {
  display: flex;
  flex-grow: 1;
  overflow: auto;
  padding-left: 40px;
}

.Report__content {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  flex-grow: 1;
  overflow: auto;
  min-height: 0;
  margin-bottom: 24px;

  .visualization {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    margin-bottom: 10px;
    margin-top: 10px;
    margin-left: auto;

    .Visualization {
      min-width: 200px;
    }
  }
}
