@use '@carbon/styles/scss/themes';
@use '@carbon/styles/scss/theme';

.Modal {
  @include theme.theme(themes.$g10);

  .cds--modal-header {
    margin-bottom: 0;
    padding-bottom: 0.5rem;
  }

  .cds--modal-content {
    // if there is a margin at the bottom, clicking on it causes te modal to close
    // setting the padding instead solves the issue
    margin-bottom: 0;
    padding-bottom: 3rem;
  }

  &.overflowVisible {
    overflow-y: auto;

    .cds--modal-container,
    .cds--modal-content {
      overflow: visible;
    }

    .cds--modal-container {
      max-height: max-content;
      height: max-content;
      margin: auto;
    }
  }

  &[aria-hidden='true'] {
    .djs-hit {
      pointer-events: none;
    }
  }
}
