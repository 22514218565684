.DefinitionSelection {
  width: 100%;

  .LoadingDefinitions {
    display: inline-block;
    width: 100%;
    vertical-align: middle;

    > div {
      block-size: 2rem;
    }
  }

  .popoverContent {
    max-width: max-content;
  }

  .cds--list-box {
    background-color: var(--cds-field-02);
  }

  .container {
    display: flex;
    flex-grow: 1;
    flex-direction: column;

    &.withTenants {
      .selectionPanel {
        .dropdowns .tenant.entry {
          display: inline-block;
        }
      }
    }

    &.large {
      height: 400px;
    }

    &:not(.large) {
      .selectionPanel {
        margin-bottom: 20px;
      }
    }

    .selectionPanel {
      margin-bottom: 40px;

      .info {
        margin-top: 10px;
        margin-bottom: -25px;
        width: 400px;

        .error {
          color: var(--cds-text-error);
        }
      }

      .dropdowns {
        display: inline-flex;
        flex-direction: row;

        .entry {
          &:not(:first-child) {
            margin-left: 10px;
          }

          &:first-child {
            margin-left: 0;
            width: 340px;
          }

          &.tenant {
            display: none;
            width: 120px;
          }

          &.version {
            width: 120px;
          }
        }

        .MultiSelect .Tag {
          background-color: var(--blue-base-65);
          color: var(--white);

          > .Button.close {
            color: var(--white);
          }
        }
      }
    }

    .diagram {
      display: flex;
      flex-grow: 1;

      hr {
        position: absolute;
        left: 0;
        border: none;
        border-bottom: 1px solid var(--silver-darken-80);
        width: 100%;
        margin: 0;
      }

      .BPMNDiagram {
        padding: 10px 0;
      }
    }
  }

  &:not(.Popover) .container .selectionPanel .dropdowns {
    width: 100%;

    .entry:first-child {
      width: auto;
      flex: 1;
    }
  }
}
