@use '@carbon/styles/scss/spacing';

.ZoomControls {
  display: flex;
  flex-direction: column;
  position: absolute;
  right: spacing.$spacing-05;
  bottom: spacing.$spacing-05;
  z-index: var(--z-above);

  > span {
    background-color: var(--cds-background);

    &:first-of-type {
      margin-bottom: spacing.$spacing-02;
    }

    &:last-of-type button {
      border-top: none;
    }
  }
}
