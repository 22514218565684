.TextEditor {
  .SimpleEditor {
    width: 100%;
    height: 150px;
    resize: none;

    .cds--text-area__wrapper {
      flex-grow: 1;
    }
  }

  &.CharCount {
    margin-top: 0.75rem;
    text-align: right;
    font-size: 14px;
    color: var(--cds-text-secondary);

    &.error {
      color: var(--red-darken-38);
    }
  }
}
