@use '@carbon/styles/scss/spacing';
@use '@carbon/type';
@use '@carbon/colors';

.DefinitionList {
  flex-grow: 1;

  &.empty {
    font-size: 11px;
    color: var(--grey-lighten-56);
    text-align: center;
  }

  li {
    background: var(--white);
    border: 1px solid var(--silver-darken-87);
    border-radius: 5px;
    padding: 10px;
    position: relative;
    margin-top: 5px;

    &:hover,
    &.active {
      background: var(--silver-lighten-99);
    }

    h4 {
      font-size: 13px;
      color: var(--grey-darken-33);
      margin-bottom: 3px;
      margin-right: 90px;
    }

    > .info {
      font-size: 11px;
      color: var(--grey-lighten-56);
      margin-right: 90px;
    }

    > .actions {
      display: flex;
      position: absolute;
      top: 7px;
      right: 7px;
    }
  }

  &.Popover {
    > .cds--popover {
      > .popoverContent {
        width: 680px;
        max-width: 680px;
        padding-bottom: spacing.$spacing-06;

        .definitionName {
          color: colors.$black;
          font-size: type.type-scale(2);
        }

        .selectionPanel {
          display: flex;
          align-items: flex-end;

          .entry {
            width: 108px;
            margin-right: spacing.$spacing-06;
          }
        }
      }
    }
  }
}
