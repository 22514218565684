.TargetValueComparison {
  width: 180px;
  display: inline-flex;
  flex-grow: 1;

  .toggleButton.cds--btn--ghost,
  .editTargetButton {
    color: var(--cds-text-primary, #161616);
  }

  .toggleButton {
    flex-grow: 1;
    // This is the default left padding value
    // we use this value on the right side to make the longer text fit
    padding-inline-end: calc(var(--cds-layout-density-padding-inline-local) - 0.0625rem);
  }
}
