.NoDataNotice {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  height: 100%;
  border-radius: 8px;
  padding-left: 25px;
  overflow: auto;

  &.warning .Icon {
    color: var(--orange-base-60);
  }

  &.error .Icon {
    color: var(--red-darken-38);
  }

  &.info .Icon {
    color: var(--grey-lighten-56);
  }

  .container {
    align-self: center;
    margin: 0 auto;

    h1 {
      font-size: 14px;
      font-weight: bold;
      margin-bottom: 3px;
      position: relative;
      color: var(--grey-darken-36);

      .Icon {
        position: absolute;
        left: -25px;
        margin-right: 10px;

        svg {
          min-height: 20px;
          min-width: 20px;
        }
      }
    }

    p {
      font-size: 12px;
      margin: 0;
    }
  }
}
