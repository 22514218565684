.Statistics {
  width: 500px;
  overflow: auto;
  padding: 0.5em;
  border-left: 1px solid var(--cds-border-subtle);
  position: relative;

  ul.branchDistribution {
    list-style: initial;
    padding-left: 40px;
  }

  .diagram-container {
    width: 100%;
    height: 100px;
    display: inline-block;
  }

  .placeholder {
    margin: 1em;
    background-color: var(--cds-layer);
    position: absolute;
    font-size: 1.4em;
    padding: 1em;
    color: var(--cds-text-secondary);
    text-align: center;
    display: flex;
    align-items: center;
    height: auto;
  }
}
