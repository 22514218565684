@import 'shared-styles/shared-styles.scss';

.NavItem {
  display: flex;
  height: 100%;
  min-width: 0;

  .arrow {
    align-self: center;
  }
}
