.DashboardRenderer {
  background-position: 6px 6px;
  background-size: calc(100% / 18 - 0.5px) 104px;
  margin-inline: -10px;
  width: calc(100% + 20px);

  .grid-entry {
    background-color: var(--white);
    border: 1px solid var(--silver-darken-80);
    display: flex;
    border-radius: 4px;
    flex-grow: 1;
    transition: none;

    &:not(:focus-within) .Popover .cds--popover {
      display: none;
    }

    &:focus-within {
      z-index: var(--z-above);
    }
  }

  .react-grid-placeholder {
    z-index: var(--z-below);
    background-color: var(--blue-darken-55);
  }

  &.isDragging .grid-entry:not(.react-draggable-dragging):not(.resizing) {
    transition: all 200ms ease;
  }
}

.dark {
  .DashboardRenderer {
    .grid-entry {
      background-color: var(--grey-darken-13);
      border: 1px solid var(--grey-darken-33);
    }
  }
}
