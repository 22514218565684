@use '@carbon/colors';

.GroupBy {
  display: flex;

  .hasNoGrouping .cds--list-box {
    background-color: transparent;
    border-bottom: none;
    border: 1.5px dashed colors.$gray-30;

    .cds--list-box__label {
      color: colors.$gray-50;
    }

    .cds--list-box__menu-icon {
      display: none;
    }
  }
}
