.SelectionFilter {
  .customValue {
    display: flex;
    align-items: center;

    .cds--toggle__text {
      display: none;
    }

    .valueSelection {
      min-width: 200px;
      margin-right: 8px;

      input {
        background-color: var(--cds-field-02);
      }
    }
  }

  .customValueAddButton {
    margin-left: auto;
  }

  .filterText b {
    font-weight: normal;
  }
}
