.cds--modal-content {
  .cds--tabs {
    width: calc(100% + 2rem);
    margin-left: -1rem;
    margin-right: -1rem;

    .cds--tab--list {
      flex-grow: 1;

      .cds--tabs__nav-item {
        flex: 0 0 auto;
      }

      .cds--tabs__nav-item + &::after {
        margin-left: 0.0625rem;
      }

      &::after {
        content: '';
        display: block;
        flex: 1;
        margin-left: 0.0625rem;
        border-bottom: 2px solid var(--cds-border-subtle);
      }
    }
  }
}

.cds--tabs {
  .cds--tab--list > a {
    display: flex;
    align-items: center;
  }
}

.cds--tab-content {
  min-height: 0;
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 0;

  &:not([hidden]) {
    height: 100%;
    flex-grow: 1;
  }
}
