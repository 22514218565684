.Number {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  overflow: auto;

  .container {
    padding: 0 10px;
    overflow-y: auto;
    margin: 0 auto;

    .data {
      font-weight: bold;
      color: var(--grey-darken-23);
    }
    .label {
      font-size: 12px;
      color: var(--grey-base-40);
      white-space: normal;
      margin-bottom: 20px;
    }
  }
}

.dark .Number {
  .container {
    .data,
    .label {
      color: var(--silver-darken-87);
    }
  }
}
