.Report.ReportView {
  .reportHeader {
    margin-bottom: 40px;
  }

  .Report__content {
    padding-right: 40px;

    &.hidden {
      display: none;
    }
  }

  .IncompleteReport {
    font-size: 20px;

    p {
      top: 25%;
    }
  }

  .viewsContainer {
    display: flex;
    flex-direction: column;
    flex: 1;
    min-height: 0;
  }

  .CollapsibleContainer {
    .InstanceViewTable {
      height: calc(100% - 48px);
    }
  }
}
