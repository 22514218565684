@use '@carbon/styles/scss/spacing';

.IngestedEvents {
  height: 100%;
  display: flex;
  flex-direction: column;

  .title {
    font-size: 1.5rem;
    line-height: 2rem;
    font-weight: normal;
  }

  .deleteLimitReached {
    max-width: 100%;
  }
}
