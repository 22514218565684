.EntityDescription {
  display: flex;
  padding-right: 48px;
  margin-top: 1rem;
  font-size: 14px;
  align-items: center;

  .description {
    margin: 0;
    word-break: break-word;

    &.overflowHidden {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  .toggle {
    align-self: flex-end;
  }

  .description {
    font-size: inherit;
    line-height: 1.5;
  }

  .toggle,
  .edit {
    margin-left: 1rem;
  }
}
