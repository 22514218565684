.PickerDateInput {
  position: relative;

  .cds--text-input {
    width: 150px;
    padding-right: 2rem;
  }

  .icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 1rem;
    pointer-events: none;
  }

  .cds--form-requirement {
    position: absolute;
    top: 40px;
  }

  &.isInvalid {
    .icon {
      display: none;
    }
  }
}
