@import 'shared-styles/shared-styles.scss';

.KpiResult {
  min-width: max-content;

  .kpi {
    > .title {
      max-width: 200px;
      @include overflow-ellipsis;
    }
    a svg {
      display: inline-block;
    }

    &:not(:first-child) {
      border-top: 1px solid var(--cds-border-subtle);
      margin-top: var(--cds-spacing-04);
      padding-top: var(--cds-spacing-04);
    }

    .reportValues {
      margin-top: var(--cds-spacing-04);

      .reportValue {
        margin-right: var(--cds-spacing-04);
        color: var(--cds-support-error);

        &.success {
          color: var(--cds-support-success);
        }
      }
    }
  }
}
