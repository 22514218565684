.DateFilter__Dashboard {
  width: 190px;
  &.fixed {
    width: max-content;
  }

  .filterMenu {
    width: 100%;
  }

  .to {
    margin-inline: 2px;
  }

  .Popover .popoverContent {
    width: auto;
    max-width: unset;
  }

  .DateFields {
    .PickerDateInput {
      &::after {
        display: none;
      }
    }
    .dateRangeContainer {
      position: relative;
      box-shadow: none;
      border: none;
      top: 0;
      background-color: initial;

      .rdrCalendarWrapper {
        background-color: initial;
      }

      &::before,
      &::after {
        display: none;
      }
    }
  }
}
