@use '@carbon/colors';
@use '@carbon/styles/scss/spacing';

.SelectionPreview {
  height: auto;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 0;
  background-color: var(--cds-field-02, #ffffff);
  gap: spacing.$spacing-05;

  &.disabled {
    color: colors.$gray-50;
  }

  &.highlighted {
    background: colors.$blue-60;
    color: colors.$white;

    svg {
      fill: colors.$white;
    }
  }
}
