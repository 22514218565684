.Configuration {
  > .configurationPopover > .cds--popover > .popoverContent {
    min-width: 320px;
    width: auto;

    hr {
      width: calc(100% + 2rem);
      margin: 8px 0;
      margin-left: -1rem;
      border: none;
      border-bottom: 1px solid var(--cds-border-subtle);
    }

    .resetButton {
      display: block;
      margin-left: auto;
    }
  }

  .cds--toggle {
    min-width: 0;

    .cds--toggle__switch {
      flex-shrink: 0;
    }

    .cds--toggle__appearance {
      // This is to make the ellipsis text wrap
      display: flex;
    }
  }

  .cds--btn-set button {
    width: auto;
    flex: 1;
    // This is the value of the carbon button left margin
    padding-inline-end: calc(var(--cds-layout-density-padding-inline-local) - 0.0625rem);
  }

  fieldset:not(:last-of-type) {
    width: 100%;
    padding-bottom: 0.75rem;
    border-bottom: 1px solid var(--cds-border-subtle);
  }
}
