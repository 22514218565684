@use '@carbon/styles/scss/spacing';

.EntityList {
  .entityIcon {
    align-self: center;

    > svg {
      display: block;
      width: 20px;
      height: 20px;
    }
  }

  .rowName {
    font-weight: 500;
  }

  thead th.tableHeader {
    white-space: nowrap;

    .cds--table-header-label {
      display: flex;
      align-items: center;
      gap: spacing.$spacing-02;
    }
  }

  tbody tr:hover .ListItemAction {
    opacity: 1;
  }

  .loadingIndicator {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 50%);
  }
}
