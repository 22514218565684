@use '@carbon/styles/scss/utilities' as *;

.SourcesModal {
  .Table {
    height: 500px;
    .cds--table-toolbar {
      min-block-size: auto;
    }

    .cds--toolbar-content {
      height: auto;
      align-items: end;
    }

    .tenantsSelector {
      margin-right: auto;

      .cds--combo-box input {
        background-color: var(--cds-field);
      }
    }

    .cds--search {
      max-width: 300px;
    }

    thead input[type='checkbox'].hidden {
      visibility: hidden;
    }
  }
}
