.ReportDetails {
  dl {
    margin: 0;

    dt {
      font-size: 12px;
      color: var(--grey-lighten-56);
    }

    dd {
      margin: 3px 0 10px;
      font-size: 14px;
      color: var(--grey-darken-33);
    }
  }
}
