@import 'shared-styles/shared-styles.scss';

.FilterInstance {
  margin-bottom: 10px;

  .sectionTitle {
    font-size: 12px;
    display: flex;
    align-items: center;

    &.clickable {
      cursor: pointer;
    }

    .sectionToggle {
      margin-top: 3px;
      transition: transform 0.1s;

      &:not(.expanded) {
        transform: rotate(-90deg);
      }
    }

    > .cds--tooltip,
    > svg {
      margin-left: auto;
    }

    .cds--tooltip + svg {
      margin-left: 0;
    }
  }

  &.collapsed {
    > * > :not(.sectionTitle) {
      display: none;
    }
  }
}
