@use '@carbon/colors';
@import 'shared-styles/shared-styles.scss';

.ReportControlPanel {
  background-color: var(--cds-layer);
  border-left: 1px solid var(--cds-border-subtle-01);
  border-bottom: 1px solid var(--cds-border-subtle-01);
  margin-left: 20px;
  flex-shrink: 0;
  width: 320px;
  display: flex;
  flex-direction: column;

  .controlSections {
    flex: 1;
    @include scrollable;
    overflow-x: hidden;

    // This property causes scrollbar to apear over the content and not taking additional space
    // This feature is only supported by newer browsers
    // (see https://developer.mozilla.org/en-US/docs/Web/CSS/overflow#browser_compatibility)
    @supports (overflow-y: overlay) {
      overflow-y: overlay;
    }

    .cds--accordion__title > svg {
      position: relative;
      top: 3px;
      margin-right: 5px;
    }

    .cds--accordion__content {
      padding-inline: var(--cds-layout-density-padding-inline-local);
    }

    .definitionTooltip button {
      display: flex;
      align-items: center;
      gap: var(--cds-spacing-02);
    }
  }

  .filterCount {
    float: right;
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .reportSetup {
    flex-grow: 1;
    margin-top: 5px;

    > li {
      margin-top: 15px;
      display: flex;
      align-items: center;

      &:first-child {
        margin-top: 0;
      }

      &.Measure ~ .Measure {
        margin-top: 5px;
      }

      &.DistributedBy {
        margin-top: 10px;
      }

      .Select {
        min-width: 0;
        flex-grow: 1;
      }

      &.addMeasure {
        margin-top: 5px;
        margin-left: 79px;
        font-size: 12px;

        .cds--list-box {
          background-color: transparent;
          border-bottom: none;
          border: 1.5px dashed colors.$gray-30;

          .cds--list-box__label {
            color: colors.$gray-50;
          }

          .cds--list-box__menu-icon {
            display: none;
          }
        }
      }

      > .label {
        color: var(--cds-text-secondary, #525252);
        font-size: 12px;
        display: inline-block;
        flex: 0 0 79px;
        vertical-align: middle;
        text-align: right;
        padding-right: 6px;
      }
    }
  }

  .instanceCount {
    margin-top: auto;
    text-align: center;
    padding: 10px;
    font-weight: 500;
    font-size: 11px;
    color: var(--cds-text-primary, #161616);
    border-top: 1px solid var(--cds-border-subtle);
  }
}
