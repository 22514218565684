@use '@carbon/colors';

.FiltersView {
  border-top: 1px solid var(--cds-border-subtle);
  border-bottom: 1px solid var(--cds-border-subtle);
  padding: 15px 40px 20px 40px;
  margin-bottom: 20px;

  h3 {
    display: block;
    margin-bottom: 0.75rem;
  }

  .filtersContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    align-items: flex-end;

    > * {
      min-width: 190px;
      width: max-content;
      max-width: 350px;
      display: inline-block;

      .title {
        font-size: 12px;
        color: var(--cds-text-secondary);
        margin-bottom: 7px;
      }
    }

    button.reset {
      min-width: auto;
    }
  }

  > .VariableFilter__Dashboard {
    .DateFilter__Dashboard {
      width: 100%;
    }
  }

  .indicator {
    display: inline-block;
    margin-right: 0.5rem;
  }

  .indicator.active {
    color: colors.$blue-60;
  }

  hr {
    border: none;
    border-bottom: 1px solid var(--cds-border-subtle);
    margin: 8px 0;
  }
}
