.BranchAnalysis {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;

  > .content {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
  }
}

.BranchAnalysis__diagram {
  display: flex;
  flex-grow: 1;
}
