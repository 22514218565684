.MultipleVariableFilterModal {
  .orOperator {
    display: block;
    margin-bottom: 20px;
    border-top: 1px solid var(--silver-darken-87);
    padding-top: 10px;
    font-size: 12px;
    text-transform: uppercase;
  }

  .orButton {
    margin-top: 10px;
    text-transform: uppercase;
  }

  .variableContainer:last-of-type .collapsed {
    border-bottom: 1px solid var(--silver-darken-87);
    margin-bottom: 15px;
  }

  .info {
    margin-bottom: 20px;
    font-size: 14px;
  }
}
