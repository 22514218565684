.dark {
  .progressLabel {
    color: var(--silver-darken-87);
  }
}

.ProgressBar {
  position: relative;

  .goalLabel {
    display: block;
    padding-right: 10px;
    padding-left: 10px;
    white-space: nowrap;
    font-size: 18px;
    position: absolute;
  }

  .barContainer {
    height: 65px;
    position: relative;
    border-radius: 4px;
    background-color: var(--silver-darken-94);
    margin-top: 24px;

    .goalOverlay {
      height: 100%;
      position: absolute;
      color: var(--black);
      border-radius: 4px;
      background: var(--green-lighten-80);

      &.warning {
        background: var(--red-lighten-85);
      }

      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 135%;
        border-right: 2px solid var(--black);
      }
    }

    .progressLabel {
      position: absolute;
      width: 100%;
      text-align: center;
      line-height: 65px;
      font-size: 2.5em;
      color: var(--silver-lighten-99);
      text-shadow:
        -1px -1px 0 var(--black),
        1px -1px 0 var(--black),
        -1px 1px 0 var(--black),
        1px 1px 0 var(--black);
      padding: 0 0.5em;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .filledOverlay {
      height: 100%;
      background: var(--green-base-44);
      border-radius: 4px;
      min-width: 5px;

      &.goalExceeded {
        background: var(--green-base-44);
      }

      &.warning {
        background: var(--red-darken-42);
      }
    }

    .rangeLabels {
      display: flex;
      height: 25px;
      line-height: 28px;
      font-size: 1.3em;

      .rightLabel {
        flex: 1;
        text-align: right;
        margin-left: 1rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}
