.KpiSummary {
  width: fit-content;

  .ButtonTrigger {
    min-height: 0;
    padding: 0;
    min-width: 100px;
    display: flex;
    align-items: center;

    svg {
      &.error {
        color: var(--cds-support-error);
      }

      &.success {
        color: var(--cds-support-success);
      }
    }

    > div {
      padding: var(--cds-spacing-02) var(--cds-spacing-04);
      display: flex;
      align-items: center;

      span {
        margin-left: var(--cds-spacing-04);
      }

      & + div {
        border-left: 1px solid;
      }
    }
  }
}
