.EntityName {
  position: relative;
  flex-grow: 1;
  display: flex;
  width: 100%;
  padding-right: 48px;
  min-height: 35px;

  .name-container {
    position: absolute;
    display: inline-block;
    flex-shrink: 1;
    width: 100%;
    padding-right: inherit;

    margin: 0;

    .name {
      display: inline-block;

      line-height: 35px;
      font-weight: 500;
      font-size: 18px;
      color: var(--grey-darken-23);
      max-width: calc(100% - 30px);

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .Popover {
    display: inline-block;
    vertical-align: top;
    margin-top: 9px;

    .DetailsPopoverButton {
      padding: 0;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      min-height: 20px;
      margin-left: 3px;

      svg {
        margin: auto;
      }
    }

    > .cds--popover > .popoverContent {
      width: 277px;

      dl {
        margin: 0;

        dt {
          font-size: 12px;
          color: var(--grey-lighten-56);
        }

        dd {
          margin: 3px 0 10px;
          font-size: 14px;
          color: var(--grey-darken-33);
        }
      }
    }
  }
}

.dark .EntityName {
  .name-container .name {
    color: var(--silver-darken-87);
  }
}
