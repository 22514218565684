@use '@carbon/styles/scss/spacing' as *;

.VersionPopover {
  .skeleton {
    height: 20px;
  }

  .specificVersions {
    > .cds--form-item {
      margin-left: $spacing-04;
    }

    .checkboxLabel {
      display: flex;
      align-items: center;

      .cds--tag {
        margin-block: 0;
      }
    }
  }
}
