@keyframes appear {
  from {
    opacity: 0;
    margin-top: -60px;
  }
}

@keyframes disappear {
  to {
    opacity: 0;
    margin-top: -60px;
  }
}

.Notification {
  pointer-events: auto;
  margin-top: var(--cds-spacing-03);
  animation: appear 0.35s ease-in-out;

  &.closing {
    animation: disappear 0.35s ease-in-out forwards;
  }
}
