@use '@carbon/styles/scss/spacing';

.InstanceCount {
  display: flex;
  align-items: center;
  gap: spacing.$spacing-02;

  .countString,
  .defaultTrigger {
    font-size: 12px;
    color: var(--cds-text-secondary);
  }

  .instanceCountPopover {
    display: inline-flex;

    .defaultTrigger {
      padding: 0;
      min-height: auto;
      display: inline-block;
      text-decoration: underline;
      font-weight: bold;
    }

    .popoverContent {
      min-width: 397px;
      padding-top: 20px;

      .countString {
        font-size: 12px;
        margin-bottom: 14px;
        color: var(--grey-darken-33);
        font-weight: bold;
      }

      .filterListHeading {
        font-size: 12px;
        color: var(--grey-lighten-56);
        margin-bottom: 8px;
      }

      .FilterList {
        margin-top: 0;
        display: block;
        margin-bottom: 20px;

        &:last-child {
          margin-bottom: 0;
        }

        .listItem {
          width: 100%;
          margin: 0;
          margin-bottom: 5px;
          cursor: unset;
        }

        .ActionItem {
          min-height: 41px;

          button {
            display: none;
          }

          .parameterName {
            max-width: unset;
            overflow: unset;
          }

          .filterValues {
            width: max-content;
          }
        }

        .listItemConnector {
          display: none;
        }
      }
    }
  }
}
