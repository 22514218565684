@use '@carbon/styles/scss/spacing';

.EmptyState {
  padding: 2.5rem;
  display: flex;
  background-color: var(--cds-layer);
  align-items: center;

  .icon {
    margin-right: spacing.$spacing-06;
    align-self: start;
    width: 80px;
    height: 80px;
    color: #ff8201;

    .Icon {
      width: 100%;
      height: 100%;
    }

    svg {
      width: inherit;
      height: inherit;
      max-width: initial;
      max-height: initial;
    }
  }

  .content {
    margin-top: 5px;
    padding-bottom: 5px;

    .title {
      font-weight: 500;
      font-size: 1.125rem;
      color: var(--cds-text-primary);
    }

    .description {
      font-weight: 400;
      font-size: 0.875rem;
      color: var(--cds-text-primary);
      line-height: 1.25rem;
      letter-spacing: 0.01rem;
    }

    .actions {
      display: flex;
    }
  }
}
