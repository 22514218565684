.EventTable {
  position: relative;

  .cds--toolbar-content {
    height: auto;
  }

  b {
    padding: 0.875rem;
    white-space: nowrap;
  }

  .showSuggestionsToggle {
    padding-top: 0.875rem;
  }

  .Table {
    .cds--data-table-content,
    table.cds--skeleton {
      transition: height 0.25s;
      height: 300px;
    }

    &.collapsed {
      .cds--data-table-content,
      table.cds--skeleton {
        height: 0;
        overflow: hidden;
      }
    }

    .cds--search {
      max-width: 250px;
    }

    .cds--data-table-header {
      padding-bottom: 0;
    }

    table {
      thead tr {
        .tableHeader:first-child {
          min-width: 0;
          visibility: hidden;
        }

        .tableHeader:nth-child(2):before {
          width: 0;
        }
      }

      tr > :first-child {
        min-width: 0 !important;
        width: 60px !important;
        max-width: 60px !important;
      }

      tbody {
        tr {
          height: auto;

          &.mapped:hover {
            cursor: pointer;

            td {
              background-color: var(--cds-layer-hover);
              border-color: var(--cds-layer-hover);
            }
          }

          &:not(.disabled).mapped td {
            color: var(--white);
            background-color: var(--cds-button-primary);
          }

          &.suggested {
            border-left: 10px solid var(--cds-button-primary);
          }

          span.disabled {
            opacity: 0.36;
          }
        }
      }
    }
  }
}
