@use '@carbon/styles/scss/spacing';

.NodeDuration {
  .cds--modal-content {
    display: flex;
    flex-direction: column;
    gap: spacing.$spacing-05;

    .BPMNDiagram {
      min-height: 300px;
    }
  }
}
