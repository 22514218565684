.ProcessPart {
  flex-grow: 1;

  > button {
    width: 100%;
  }

  .ProcessPart__current {
    cursor: pointer;
    white-space: pre-wrap;

    .ActionItem .content {
      margin-top: -24px;

      .cds--tag {
        margin-left: 0;
      }
    }
  }
}

.ProcessPartModal {
  .cds--modal-container {
    height: 100%;
  }

  .cds--modal-content {
    display: flex;
    flex-direction: column;

    .diagram-container {
      display: flex;
      flex-grow: 1;
    }

    .SelectionPreview {
      width: auto;
    }

    .notification {
      margin-bottom: 8px;
      max-width: 100%;
    }
  }
}
