.Icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  min-width: 16px;
  min-height: 16px;
  background-repeat: no-repeat;
  background-size: cover;
  vertical-align: text-bottom;

  &:not(:last-child) {
    margin-right: 0.5em;
  }

  svg {
    min-height: 16px;
    min-width: 16px;
    max-height: 1em;
    max-width: 1em;
    fill: currentColor;
  }
}
