@use '@carbon/styles/scss/spacing';
@import 'shared-styles/shared-styles.scss';

.NodeFilter {
  .cds--modal-container {
    height: 100%;
  }

  .modalContent {
    display: flex;
    flex-direction: column;

    .diagramContainer {
      margin-top: spacing.$spacing-06;
      flex-grow: 1;
      display: flex;
    }

    .preview {
      min-height: 35px;

      .previewList {
        display: inline;
        line-height: 30px;

        .previewItem {
          display: inline-block;

          .previewItemOperator {
            white-space: pre-wrap;
          }
        }
      }
    }
  }
}
