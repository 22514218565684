.Heatmap {
  display: flex;
  flex-grow: 1;

  > .Select {
    position: absolute;
    min-width: 210px;
  }

  .Tooltip {
    width: max-content;
    text-align: left;
    line-height: 1.5em;
    .text {
      font-weight: normal;
      display: block;
    }

    button {
      margin: 10px 0;
    }

    table {
      white-space: nowrap;

      td:first-child {
        text-align: right;
        padding-right: 8px;
      }
    }
  }
}
