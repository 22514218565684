@use '@carbon/styles/scss/spacing';

.ProcessView {
  height: 100%;
  display: flex;
  flex-direction: column;

  .publishing {
    display: flex;
    align-items: center;
    margin-right: spacing.$spacing-04;
    align-self: center;

    .progressLabel {
      margin-right: spacing.$spacing-04;
      color: var(--cds-text-secondary);
    }
  }

  .header .generationWarning {
    margin-top: spacing.$spacing-04;
    min-width: 100%;
  }
}
