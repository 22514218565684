.DMNDiagram {
  .view-drd,
  .powered-by {
    display: none;
  }
}

.dark .DMNDiagram {
  .dmn-decision-table-container {
    * {
      background-color: inherit;
      color: inherit;
      border-color: inherit;
    }

    .rule-index,
    .index-column {
      background-color: var(--grey-darken-13);
    }

    .input-variable,
    .output-variable,
    .clause {
      color: inherit;
    }

    .tjs-table tbody tr:nth-child(2n) {
      background-color: var(--grey-darken-23);
    }
  }
}
