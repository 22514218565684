@use '@carbon/colors';

.UserList {
  height: 100%;
}

.AddUserModal,
.EditUserModal {
  .cds--radio-button-group {
    .cds--radio-button-wrapper {
      .cds--radio-button__label-text {
        span {
          display: block;

          &.subtitle {
            font-size: 0.75rem;
            color: colors.$gray-60;
          }
        }
      }
    }
  }
}
