@import '~bpmn-js/dist/assets/diagram-js.css';
@import '~bpmn-js/dist/assets/bpmn-font/css/bpmn.css';

.ProcessEdit {
  height: 100%;
  display: flex;
  flex-direction: column;

  .BPMNDiagram {
    // to make sure that the bpmn modelling pallet is always visible
    min-height: 370px;

    .djs-element.hover .djs-outline,
    .djs-element.selected .djs-outline {
      stroke-width: 1;
    }
  }
}
