.OutlierDetailsTable:not(.loading) {
  tr {
    height: max-content;

    td {
      text-overflow: initial;
      white-space: wrap;
    }
  }
}
