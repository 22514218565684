.selectionIndicator {
  position: absolute;
  background-color: var(--black);
  opacity: 0;
  height: 100%;
  top: 0;
  pointer-events: none;
  transition: opacity 0.2s;

  &.left {
    left: 0;
  }

  &.right {
    right: 0;
  }

  &.active {
    opacity: 0.2;
  }
}
