.FiltersEdit {
  .title {
    position: relative;
    padding-right: 4rem;

    .DeleteButton,
    .EditButton {
      position: absolute;
      top: -12px;
    }

    .DeleteButton {
      right: 0;
    }

    .EditButton {
      right: 2rem;
    }
  }
}

.dashboardVariableFilter {
  .BooleanInput,
  .DateInput {
    display: none;
  }

  .customValueCheckbox {
    display: inline-block;
  }

  .StringInput {
    .notification {
      display: none;
    }
  }

  .NumberInput,
  .StringInput.containMode {
    & + .customValueCheckbox {
      display: block;
      &::before {
        content: '';
        width: 100%;
        transform: translateY(-10px);
        display: block;
        border-top: 1px solid var(--cds-border-subtle);
      }
    }
  }
}
