.ControlPanel {
  .DefinitionSelection {
    margin-left: 0;
  }

  .zScoreTooltip {
    margin-left: 0.5rem;
    height: 1rem;
    vertical-align: -3px;
    cursor: pointer;
  }
}
