.SingleReportDetails {
  h2 {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 3px;
    overflow-wrap: break-word;
  }
  h3 {
    font-size: 12px;
    color: var(--grey-lighten-56);
    margin-bottom: 5px;
  }

  .definition + .definition {
    margin-top: 20px;
  }

  h4 {
    font-size: 14px;
    color: var(--grey-darken-23);
    margin-bottom: 3px;
  }

  .info {
    font-size: 12px;
    line-height: 1.33;
    color: var(--grey-lighten-56);
  }

  .nowrap {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .modalButton {
    width: 100%;
    margin-top: 0.5rem;
  }

  hr {
    border: none;
    border-bottom: 1px solid var(--silver-darken-87);
    margin: 15px 0;
  }
}
