@use '@carbon/layout';
@import 'shared-styles/shared-styles.scss';

.EntityNameForm {
  .info {
    flex-grow: 1;

    .name-container {
      flex-grow: 1;
      display: flex;
      width: 100%;
      padding-right: layout.$spacing-06;
    }
  }

  .tools {
    align-self: flex-start;
  }

  a.cancel-button:hover {
    text-decoration: none;
  }
}
