.ErrorPage {
  text-align: center;
  padding-top: 150px;
  font-size: 14px;

  h1 {
    font-size: 16px;
    font-weight: bold;
    color: var(--grey-lighten-56);
    white-space: pre;
    line-height: 1.25;
  }

  pre {
    margin: 60px;
    color: var(--black);
    font-family: 'Courier New', Courier, monospace;
  }
}
