.dark {
  .fullscreen {
    background-color: var(--grey-darken-13);
  }
}

.fullscreen {
  background-color: var(--white);
  transition: background-color 0.3s;
  height: inherit;
}

.fullscreen-enabled {
  overflow: auto;
}

.DashboardView {
  height: inherit;
  display: flex;
  flex-direction: column;

  > .content {
    height: calc(100% - 75px);
  }

  &.fullscreen {
    padding: 20px;

    .ReportDetails .modalButton {
      display: none;
    }
  }
}
