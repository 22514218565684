@use '@carbon/styles/scss/spacing' as *;

.ControlPanel {
  background-color: var(--cds-layer);
  padding: $spacing-04;
  margin-bottom: $spacing-04;
  display: inline;

  .DefinitionSelection {
    width: 220px;
    display: inline-block;
    margin-inline: $spacing-04;
  }

  > li {
    display: inline-block;
    margin-inline: $spacing-02;

    > * {
      // this fixes the problem with wrapped items touching each other top-bottom
      margin-block: $spacing-02;
    }
  }

  .filtersListItem {
    display: inline;
  }

  .cds--list-box {
    background-color: var(--cds-field-02);
  }

  .Filter {
    .filterHeader {
      display: inline-flex;
      margin-inline: $spacing-04;
      margin-bottom: 0;

      .dropdownLabel {
        margin-right: $spacing-04;
        flex-grow: 1;
      }

      .MenuDropdown {
        margin-block: $spacing-02;
      }
    }

    .explanation,
    .emptyMessage,
    .linkingTip {
      display: none;
    }

    .FilterList {
      display: inline-block;

      > .listItem {
        display: inline-flex;
        width: auto;
        margin-right: $spacing-04;
      }
    }
  }
}
