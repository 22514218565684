@import 'shared-styles/shared-styles.scss';

.ObjectVariableModal {
  pre {
    font-size: 14px;
    border: 1px solid var(--silver-darken-87);
    padding: 15px;
    max-height: 600px;
    overflow: auto;
  }
}
