.NodeDateFilter {
  .cds--modal-container--lg {
    height: 100%;
  }

  .cds--modal-content {
    display: flex;
    flex-direction: column;

    .diagramContainer {
      flex-grow: 1;
      display: flex;
    }
  }
}
