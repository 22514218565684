@import 'shared-styles/shared-styles.scss';

.FilterList {
  padding: 0;
  margin: 0;

  .listItem {
    margin: 5px 0;
    width: 100%;

    .ActionItem {
      .content,
      .warning,
      .header .type {
        font-size: 10px;
      }

      .content {
        & > .note {
          display: block;
          margin-top: 5px;
        }

        .DateFilterPreview + * {
          display: block;
          margin-top: 13px;
        }

        .flowNodes {
          b:first-of-type {
            margin-left: 3px;
          }
        }
      }
    }

    .parameterName {
      max-width: 230px;
      font-size: 11px;
      margin-left: 0;

      > span {
        @include overflow-ellipsis;
      }
    }

    .filterText {
      display: block;
    }

    .OrOperator {
      margin: 10px 0;
      text-transform: uppercase;
    }
  }

  .listItemConnector {
    display: inline-block;
    margin: 0 0.5em;
  }
}

.dark .FilterList .ActionItem {
  color: var(--grey-darken-13);
}
