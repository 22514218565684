.PublishModal .permission {
  h4 {
    font-weight: bold;
    margin-bottom: 10px;
  }

  > div {
    display: flex;
    align-items: center;

    button {
      margin-left: 10px;
    }
  }
}
