@use '@carbon/colors';
@import 'shared-styles/shared-styles.scss';

.ActionItem {
  border: 1px solid var(--cds-border-subtle);
  border-radius: 3px;
  background-color: colors.$white;
  width: 100%;
  position: relative;
  min-height: 32px;
  padding: 10px;
  padding-top: 5px;

  .header {
    display: flex;
    width: 100%;
    align-items: center;
    margin-left: auto;

    .type {
      color: var(--cds-text-secondary);
      font-size: 0.75em;
      flex-grow: 1;
    }

    .buttons {
      margin-left: auto;
    }
  }
  .content,
  .warning {
    font-size: 0.75em;
  }

  .content {
    max-width: 100%;
  }

  .warning {
    display: flex;
    align-items: baseline;

    svg {
      margin-left: 0.25rem;
      width: 0.75rem;
      height: 0.75rem;
    }
  }

  &:not(:hover) {
    button {
      opacity: 0;

      &.active,
      &.focus-visible {
        opacity: 1;
      }
    }
  }
}
