.CombinedReportPanel {
  width: 30%;
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  position: relative;

  .Configuration {
    left: 10px;
    top: 10px;
    position: absolute;
  }

  .TargetValueComparison {
    display: block;
    flex-shrink: 1;
    margin-bottom: 10px;
  }

  .TypeaheadMultipleSelection {
    padding: 50px 10px 0 10px;
    height: 100%;
    min-width: 200px;
    border-left: 1px solid var(--silver-darken-94);

    .instanceCount {
      font-weight: normal;
      font-size: 0.875em;
      color: var(--grey-darken-23);
    }

    .TypeaheadMultipleSelection__labeled-input {
      width: 100%;
      .TypeaheadMultipleSelection__input {
        height: 40px;
        width: 100%;
      }
    }

    .TypeaheadMultipleSelection__valueList {
      width: 100%;

      .TypeaheadMultipleSelection__labeled-valueList {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 10px 0px;
        padding: 0 20px;

        p {
          width: 100%;
          font-weight: bold;
          text-align: left;
        }

        .TypeaheadMultipleSelection__values-sublist {
          width: 100%;

          .TypeaheadMultipleSelection__valueListItem {
            border-bottom: 1px solid var(--silver-darken-94);

            &.draggable:hover {
              cursor: move;
            }

            .Popover {
              margin-left: 10px;

              .colorPickerBtn {
                padding: 0px;
                width: 20px;
                min-height: 20px;
              }

              .popoverContent {
                padding: 5px;
              }
            }
          }
        }
      }
    }
  }
}
