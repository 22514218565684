.OutlierDetailsModal {
  .cds--modal-container {
    min-height: 90%;
  }

  .cds--modal-content {
    display: flex;
    flex-direction: column;
  }

  h2 {
    margin-bottom: 1rem;
    font-weight: bold;
  }

  .description {
    > span {
      margin-left: 1.5rem;
    }
  }

  .DurationChart {
    flex: 1 0 30%;
  }

  .VariablesTable {
    max-height: 30%;
    flex: 1;
  }
}
