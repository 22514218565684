.Process {
  height: 100%;

  > .ProcessView > .header {
    margin-bottom: 40px;
  }
  > .ProcessEdit > .header {
    margin-bottom: 20px;
  }

  > .ProcessView,
  > .ProcessEdit {
    .content {
      display: flex;
      flex-grow: 1;
    }

    > .header {
      padding: 30px 40px 0 40px;

      .head {
        display: flex;
      }

      .tools {
        flex-wrap: nowrap;
        align-items: baseline;
        display: flex;
        flex-shrink: 0;

        .tool-button {
          margin-right: 6px;
        }

        .tool-button:last-child {
          margin-right: 0;
        }
      }
    }

    > .BPMNDiagram {
      margin: 0 20px;
    }
  }
}
