@import 'shared-styles/shared-styles.scss';

.AlertModal {
  .cds--modal-container {
    max-height: 90vh;

    .cds--modal-content {
      overflow: auto;
    }
  }

  .cds--actionable-notification__content {
    display: inline;
  }

  .labelHidden {
    label {
      visibility: hidden;
    }
  }

  .reset {
    align-self: flex-end;
    width: 100%;
  }

  .reportValue {
    @include overflow-ellipsis(300px);
  }
}
