@import 'shared-styles/shared-styles.scss';

.dark {
  .Editor {
    background-color: var(--gray-darken-13);
  }
}

.Editor {
  background-color: var(--white);

  .toolbar {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;

    button {
      color: var(--cds-text);
    }
  }

  .editor {
    @include scrollable;

    min-height: 300px;
    max-height: 300px;
    cursor: text;

    padding: 0.5rem;
    word-break: break-all;

    img {
      max-width: 100%;
    }

    &.focus-visible {
      box-shadow: none;
    }

    &.error {
      outline: 2px solid var(--cds-support-error, #da1e28);
      outline-offset: -2px;
    }

    & {
      .p {
        margin: 0;
        margin-bottom: 0.25rem;
      }

      .code {
        background-color: var(--silver-darken-87);
        font-family: monospace;
        font-size: initial;
        border-radius: 2px;
        padding: 0.125rem 0.25rem;
      }

      .h1,
      .h2,
      .h3 {
        font-weight: bold;
      }

      .h2 {
        font-size: 1.75em;
        margin-bottom: 0.4em;
      }

      .h3 {
        font-size: 1.5em;
        margin-bottom: 0.3em;
      }

      .bold {
        font-weight: bold;
      }

      .italic {
        font-style: italic;
      }

      .underline {
        text-decoration: underline;
      }

      .strikethrough {
        text-decoration: line-through;
      }

      // These styles are globally disabled, so we need to re-enable them here
      ul,
      ol {
        margin: 1em 0;
        padding-left: 2rem;
      }

      ul {
        list-style-type: disc;
      }
    }
  }

  .toolbar {
    border: 1px solid var(--silver-darken-87);
  }

  &,
  .editor {
    height: 100%;
  }
}
