@use '@carbon/styles/scss/themes';
@use '@carbon/styles/scss/theme';
@use '@carbon/styles/scss/spacing';
@import 'shared-styles/shared-styles.scss';

.dark .Popover > .cds--popover > .popoverContent {
  @include theme.theme(themes.$g100);
}

.Popover {
  display: block;

  &.cds--popover--tab-tip > .cds--popover > .popoverContent {
    min-width: 100%;
  }

  > .cds--popover > .popoverContent {
    @include theme.theme(themes.$g10);
    padding: spacing.$spacing-05;

    &.scrollable {
      @include scrollable;
    }
  }

  .ListBoxTrigger,
  .ButtonTrigger {
    min-width: 100%;
  }

  .ButtonTrigger.active {
    box-shadow: 0 2px 6px var(--cds-shadow, rgba(0, 0, 0, 0.3));

    &.cds--btn--ghost {
      background-color: var(--cds-layer);
    }
  }
}
