.Checkmark {
  flex: 0 0 50px;
  color: var(--cds-support-error);
  position: relative;
  height: 18px;

  svg {
    color: var(--cds-support-info);
  }

  .tooltipContent {
    white-space: nowrap;

    span {
      font-weight: bold;
    }
  }
}
