.CollapsibleSection {
  border-top: 1px solid var(--cds-border-subtle);

  &.collapsed {
    > :not(.sectionTitle) {
      display: none;
    }
  }

  .sectionTitle {
    padding: 0.625rem 0;
    text-transform: uppercase;
    width: 100%;
    background: transparent;
    border: none;
    text-align: left;
    cursor: pointer;

    .sectionToggle {
      float: right;
      transition: transform 0.1s;

      &:not(.open) {
        transform: rotate(-90deg);
      }
    }
  }
}
