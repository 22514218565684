@import 'shared-styles/shared-styles.scss';

.VariableFilter__Dashboard {
  > .cds--popover-container {
    width: 100%;
  }

  .cds--tooltip {
    display: block;
  }
  .title button.tooltipTrigger {
    @include overflow-ellipsis;
    width: 100%;
    margin: 0;
    padding: 0;
    border: none;
    background-color: inherit;
    font: inherit;
    color: inherit;
    text-align: left;
  }
}
