.ColumnRearrangement {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.ColumnRearrangement .tableHeader {
  &:not([data-group]):not(button):hover:after,
  &.childDragged:after {
    cursor: move;
    height: 100%;
    width: 20px;
    content: '.';
    position: absolute;
    left: 5px;
    top: 0;
    font-size: 20px;
    font-weight: normal;
    color: var(--grey-base-40);
    text-shadow:
      0 5px var(--grey-base-40),
      0 10px var(--grey-base-40),
      5px 0 var(--grey-base-40),
      5px 5px var(--grey-base-40),
      5px 10px var(--grey-base-40);
  }
}

.ColumnRearrangement__draggedColumn {
  opacity: 0.3;
}

.Table tr .ColumnRearrangement__dropTarget--left {
  box-shadow: -2px 0 0 var(--green-base-44) inset;
}

.Table tr .ColumnRearrangement__dropTarget--right {
  box-shadow: 2px 0 0 var(--green-base-44) inset;
}

.ColumnRearrangement__dragPreview {
  background-color: var(--white);
  width: 250px;
  min-width: 0 !important;
  max-height: 400px;
  z-index: var(--z-above);
  transform: scale(0.7);
  transform-origin: top left;
  pointer-events: none;
  position: absolute;
  overflow: hidden;

  table {
    overflow: hidden;
    tr {
      max-width: max-content;

      .placeholder {
        display: none;
      }
    }
  }

  .tableFooter {
    display: none;
  }
}
