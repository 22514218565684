@use '@carbon/styles/scss/spacing';

.ConnectionStatus {
  .error {
    font-size: 0.7em;
  }

  .statusItem {
    display: inline-block;
    margin-right: spacing.$spacing-04;
    white-space: nowrap;

    &:before {
      content: '';
      display: inline-block;
      width: 0.65em;
      height: 0.65em;
      border-radius: 100%;
      margin-right: spacing.$spacing-02;
      background-color: var(--cds-support-error);
    }

    &.is-connected:before {
      background-color: var(--cds-support-success);
    }

    &.is-in-progress:before {
      width: 0.8em;
      height: 0.8em;
      background-size: cover;
      background-color: inherit;
      background-image: url(./loading.svg);
    }
  }
}
