.ClickBehavior__node {
  cursor: pointer;
}

.ClickBehavior__disabled {
  opacity: 0.3;
}

.djs-shape.ClickBehavior__node {
  opacity: 1;
}

.djs-shape.ClickBehavior__node.hover .djs-outline {
  stroke-width: 2px !important;
  stroke: var(--blue-darken-62) !important;
  fill: var(--silver-darken-80) !important;
  fill-opacity: 0.4;
  visibility: visible;
}

.djs-shape.ClickBehavior__node--selected .djs-outline {
  visibility: visible;
  shape-rendering: initial !important;
  stroke-dasharray: none !important;
  stroke-width: 2px !important;
  stroke: var(--blue-darken-62) !important;
  fill: var(--blue-lighten-82) !important;
  fill-opacity: 0.6;
}

.djs-shape.ClickBehavior__node--selected.hover .djs-outline {
  stroke-width: 2px !important;
  stroke: var(blue-darken-55) !important;
  fill: var(--blue-darken-48) !important;
  fill-opacity: 0.6;
}
