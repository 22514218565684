@use '@carbon/colors';

:root {
  --silver-lighten-99: #fcfcfc;
  --silver-base-97: #f8f8f8;
  --silver-darken-94: #efefef;
  --silver-darken-87: #dedede;
  --silver-darken-80: #cdcdcd;
  --grey-lighten-74: #bdbdbd;
  --grey-lighten-56: #909090;
  --grey-lighten-50: #7f7f7f;
  --grey-base-40: #666666;
  --grey-darken-36: #5d5d5d;
  --grey-darken-33: #4c4c4c;
  --grey-darken-23: #3b3b3b;
  --grey-darken-13: #212121;
  --blue-lighten-93: #{colors.$blue-20};
  --blue-lighten-82: #{colors.$blue-40};
  --blue-lighten-75: #{colors.$blue-40};
  --blue-base-65: #{colors.$blue-60};
  --blue-darken-62: #{colors.$blue-60-hover};
  --blue-darken-55: #{colors.$blue-60};
  --blue-darken-48: #{colors.$blue-60};
  --blue-darken-32: #{colors.$blue-70};
  --blue-darken-18: #{colors.$blue-80};
  --red-lighten-85: #ffb3b3;
  --red-base-62: #ff3d3d;
  --red-darken-52: #ff0a0a;
  --red-darken-42: #d60000;
  --red-darken-38: #c20000;
  --red-darken-26: #850000;
  --red-darken-18: #5c0000;
  --orange-base-60: #ffa533;
  --green-base-44: #10d070;
  --grey-darken-33-2: #535353;
  --color-ui-dark-01: #1c1f23;
  --white: #ffffff;
  --black: #000000;
  --orange-lighten-85: #fff2e3;
  --green-lighten-80: #cbf7e1;
}
