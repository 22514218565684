.ReportRenderer {
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  min-height: 0;

  .component {
    display: flex;
    flex-grow: 1;
    max-width: calc(100% - 175px);
    position: relative;
    flex-direction: column;

    &:only-child {
      max-width: 100%;
    }
  }

  .additionalInfo {
    display: flex;
    flex-grow: 0;
    flex-direction: column;
    font-size: 1.4em;
    padding-top: 0.5em;
    text-align: center;
    border-left: 1px solid var(--silver-darken-80);
    width: 175px;
    overflow-wrap: break-word;
    hyphens: auto;
  }
}
