.cds--tooltip .appliesTo {
  ul {
    padding-left: 1rem;
    list-style: initial;
  }
}

.appliedTo {
  margin-top: 6px;
  margin-bottom: 0;
  color: var(--blue-darken-55);
  display: inline-block;
}
