@use '@carbon/styles/scss/spacing';

.DurationHeatmapModal {
  .content-container {
    display: flex;
    flex-direction: column;
    gap: spacing.$spacing-05;
  }

  .BPMNDiagram {
    min-height: 300px;
  }

  .Table {
    .selection {
      display: flex;
      min-width: 0;
      flex-grow: 1;
      align-items: flex-start;
      justify-content: space-between;
      flex-direction: row;

      .cds--form-item {
        min-width: 0;
        flex-grow: 1;
      }

      .Select {
        margin-left: 0.5rem;
        min-width: 130px;
      }
    }
  }

  .errorMessage {
    margin-top: 1rem;
    max-width: 100%;
  }
}
