.CountTargetInput {
  .targetInput {
    position: relative;

    .percentageIndicator {
      position: absolute;
      bottom: 0.725rem;
      right: 2.25rem;
      fill: var(--cds-text-secondary);

      &[aria-disabled='true'] {
        fill: var(--cds-text-disabled);
        cursor: not-allowed;
      }
    }
  }
}
