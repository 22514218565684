.CollapsibleContainer {
  height: 100%;
  max-height: 348px;
  transition: max-height 0.25s;
  overflow: hidden;

  &.minimized {
    max-height: 48px;
  }

  &.maximized {
    // This is just a default.The exact value is calculated in the component
    max-height: 100%;
  }

  .toolbar {
    background-color: var(--cds-layer);
    display: flex;
    justify-content: space-between;
    height: 48px;
    align-items: center;
    padding-left: 16px;
    font-size: 14px;

    .controls {
      margin-left: auto;
    }
  }
}
